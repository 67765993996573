/* Style the buttons that are used to open and close the collapsible panel */
.collapsible {
  display: inline;
  align-items: left;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.collapsible:hover,
.active {
  background-color: transparent;
}

/* Style the collapsible content panel. Note: hidden by default */
.collapsible__content {
  overflow: hidden;
  transition: height 0.6s linear;
  text-indent: 0rem;
}

/* Style the collapsible content text */
.collapsible__text {
  padding-bottom: 0.35rem;
}

/* Style the collapsible section */
.collapsible__section {
  /* display: flex; */
  display: inline;
  flex-direction: column;
  flex-basis: auto;
}
