@font-face {
  font-family: Karelia-Regular;
  src: url("./assets/fonts/Karelia-Regular.otf") format("opentype");
  font-display: block;
}

@font-face {
  font-family: Karelia-RegularItalics;
  src: url("./assets/fonts/Karelia-RegularItalic.otf") format("opentype");
  font-display: block;
}

@font-face {
  font-family: Karelia-Outtakes;
  src: url("./assets/fonts/Karelia-Outtakes.otf") format("opentype");
  font-display: block;
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,300;0,400;1,300;1,400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-size: calc(
    9px + (17 - 10) * ((100vw - 300px) / (1600 - 300))
  ); /* If you change this font size, you must also change the border of the PopUpImage on Home.js. */
}
/* @media screen and (max-width: 700px) {
  html {
    font-size: 10px;
    color: red;
  }
}
@media screen and (min-width: 700px) {
  html {
    font-size: 16px;
    color: navy;
  }
} */
body {
  margin: 0;
  padding: 0.4rem 1rem 0.9rem 1.1rem;
  font-family: "Karelia-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------- react slick settings: ---------------------------------- */

.arrow {
  margin: 0;
  position: absolute;
  top: 0%;
  cursor: pointer;
  user-select: none;
  height: 100%;
  width: 15%;
  padding: 0rem !important;
}
.arrow span {
  margin-top: 15rem;
  line-height: 1rem;
  transform: translateY(-50%);
  display: block;
}
@media screen and (min-width: 700px) {
  .arrow {
    width: 33%;
  }
  .arrow span {
    margin-top: 22.5rem;
  }
}

.nextArrow {
  right: -2rem;
  text-align: right;
  margin-right: 5rem;
}
@media screen and (min-width: 700px) {
  .nextArrow {
    margin-right: 15rem;
  }
}

.prevArrow {
  left: -2rem;
  text-align: left;
  margin-left: 5rem;
}
@media screen and (min-width: 700px) {
  .prevArrow {
    margin-left: 15.1rem;
  }
}

.slick-slider,
.slick-list,
.slick-track {
  height: auto;
  /* height: 31rem; */
}

.slick-slide img {
  width: 99%;
  max-height: 30rem;
}
.slick-slide div {
  height: auto;
}

@media screen and (min-width: 700px) {
  .slick-slider,
  .slick-list,
  .slick-track {
    height: auto;
    /* height: 47rem; */
  }
  .slick-slide img {
    width: 99%;
    max-height: 45rem;
  }
  .slick-slide div {
    height: auto;
  }
}

.slick-slide div {
  outline: none;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  /* object-fit: contain; */
  display: block;
  object-fit: contain !important;
  /* width: 100%;
  height: auto; */
}

.slick-track {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
